<template>
  <b-card no-body>
    <b-card-body>
      <h4>Manage Inter-Model Links</h4>
      <p>
        Create, view, and delete links between models.
        Linking models enables links/references to components in the target model.
      </p>
    </b-card-body>

    <!-- Links Table -->
    <b-table
      :items="tableData"
      :fields="fields"
      responsive
      fixed
      hover
      @row-clicked="item=>$set(item, '_showDetails', !item._showDetails)"
    >
      <!-- model-name -->
      <template #cell(model)="data">
        <div
          class="font-weight-bold cursor-pointer"
          @click="data.toggleDetails"
        >
          {{ data.item.name }} <span class="font-small-2">({{ data.item.key }})</span>
        </div>
      </template>

      <template #row-details="data">
        <div class="row-details p-2">
          <div>
            <h5>UUID</h5>
            <p class="ml-1 text-nowrap select-all">
              {{ data.item.id }}
            </p>
          </div>
          <div>
            <h5>Classification</h5>
            <p class="ml-1">
              {{ data.item.default_security_classification ? data.item.default_security_classification : '-' }}
            </p>
          </div>
          <div>
            <h5>Description</h5>
            <p class="ml-1">
              {{ data.item.description ? data.item.description : '-' }}
            </p>
          </div>
          <div>
            <h5>Scope</h5>
            <p class="ml-1">
              {{ data.item.scope ? data.item.scope : '-' }}
            </p>
          </div>
          <div>
            <h5>Created</h5>
            <p class="ml-1">
              {{ data.item.created | formatDate }}
            </p>
          </div>
        </div>
      </template>

      <!-- linked-by -->
      <template #cell(linked_by)="data">
        <div v-if="data.item.linked_by !== ''">
          <span>{{ data.item.linked_by }}</span>
        </div>
        <div v-else>
          <span class="font-small-3 italic">(Not linked)</span>
        </div>
      </template>

      <!-- date-created -->
      <template #cell(linked_date)="data">
        <div class="d-flex flex-column">
          <span class="font-weight-bolder mb-25">{{ data.item.linked_date | diffForHumans }}</span>
          <span class="font-small-2 text-muted text-nowrap">
              {{ data.item.linked_date | formatDate }}
            </span>
        </div>
      </template>

      <!-- row-actions -->
      <template #cell(actions)="data">
        <div>
          <b-button-group size="sm" class="mx-auto">
            <b-button variant="outline-primary" @click="data.toggleDetails">
              Details
            </b-button>
            <b-button
              v-if="data.item.linked_by === ''" variant="outline-success" data-cy="btn-create-link"
              @click="createLinkTo(data.item.id)"
            >
              <feather-icon
                icon="LinkIcon"
                class="mr-50 text-success"
              />
              <span>Create Link</span>
            </b-button>
            <b-button v-else disabled variant="outline-success" data-cy="btn-create-link-disabled">
              <feather-icon
                icon="LinkIcon"
                class="mr-50 text-success"
              />
              <span>Already Linked</span>
            </b-button>
          </b-button-group>
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { BTable, BButton } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'

export default {
  name: 'ModelLinksTable',
  components: {
    BTable,
    BButton,
  },
  props: {
    modelId: {
      type: String,
    },
  },
  data() {
    return {
      modelInfo: {},
      tableData: [],
      fields: [
        {
          key: 'model', label: 'Target', class: 'text-center', thStyle: { width: '22%' },
        },
        {
          key: 'owner', label: 'Owner', class: 'text-center', thStyle: { width: '25%' },
        },
        {
          key: 'linked_by', label: 'Linked By', class: 'text-center', thStyle: { width: '20%' },
        },
        {
          key: 'linked_date', label: 'Linked', class: 'text-center', thStyle: { width: '20%' },
        },
        {
          key: 'actions', label: 'Actions', class: 'text-center', thStyle: { width: '30%' },
        },
      ],
    }
  },
  computed: {
    modelName() {
      return this.modelInfo?.properties?.name
    },
  },
  async mounted() {
    await this.fetchModelsList()
  },
  methods: {
    async fetchModelsList() {
      this.modelInfo = await this.$coreService.modelApi.getModel(this.modelId)
      let linkedResult = (await this.$coreService.modelApi.getLinkedModels(this.modelId))
      linkedResult = Array.isArray(linkedResult) ? linkedResult : []
      const allResult = [
        ...await this.$coreService.modelApi.getModelsShared(),
        ...await this.$coreService.modelApi.getModelsPublic(),
        ...await this.$coreService.modelApi.getModelsByUser(),
      ]
      // the models have a link if the rel has a created_by field filled
      this.tableData = allResult.filter(m => m.id !== this.modelId)
        .map(m => {
          const lr = linkedResult?.find(m2 => m2.id === m.id)
          return { ...m, linked_by: lr?.created_by || '', linked_date: lr?.created }
        })
    },
    async deleteLinkTo(modelId) {
      await this.$coreService.modelApi.deleteModelLink(this.modelId, modelId)
    },
    async createLinkTo(modelId) {
      const targetName = this.tableData.find(td => td.id === modelId).name
      const confirmed = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to link to ${targetName}?`,
        { centered: true },
      )
      if (!confirmed) return
      await this.$coreService.modelApi.createModelLink(this.modelId, modelId)
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Linked Models',
          icon: 'CheckIcon',
          text: `Can now share components with ${targetName}`,
          variant: 'success',
        },
      })
      await router.push({ name: 'model_links', params: { modelId } })
    },
  },
}
</script>

<style lang="scss">
.max-8 {
  max-width: 8rem;
}
</style>
<style lang="scss" scoped>
@import 'src/assets/scss/style.scss';

.col-divider > * + * {
  border-top: 0;
  border-bottom: 0;

  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));

  --tw-divide-opacity: 0.1;
  border-color: rgba(0, 0, 0, var(--tw-divide-opacity));
  border-style: solid;
}

.row-details {
  @extend .border-rounded-lg;
  background: whitesmoke;
}

.dark-layout {
  .row-details {
    background: rgba(255, 255, 255, 0.05)
  }

  .col-divider > * + * {
    --tw-divide-opacity: 0.1;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity));
  }
}
</style>
